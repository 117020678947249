<template>
  <div class="page">
    <Navbar title="个人相册" :callback="back" type="SMP" />
    <div class="list" v-for="photo in photoList" :key="photo">
      <van-row>
        <van-col span="24">
          <van-image width="100%" :src="photo.path"></van-image>
          <div class="description">{{ photo.description }}</div>
          <div class="btn">
            <van-row>
              <van-col span="10" :offset="1">
                <van-button
                  :color="COLOR_M"
                  icon="plus"
                  size="mini"
                  block
                  @click="updatePhoto(photo.seq)"
                  >修改</van-button
                >
              </van-col>
              <van-col span="10" :offset="2"
                ><van-button
                  :color="COLOR_S2"
                  icon="plus"
                  size="mini"
                  block
                  @click="deletePhoto(photo.seq, photo.code)"
                  >删除</van-button
                ></van-col
              >
            </van-row>
          </div>
        </van-col>
      </van-row>
    </div>
    <van-row class="no-record" v-if="photoList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <div class="operate">
      <van-row>
        <van-col span="24">
          <van-button
            class="btn"
            icon="plus"
            :color="COLOR"
            size="small"
            @click="createPhoto()"
            >新增照片</van-button
          >
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      photoList: [{ path: 'https://open-image-1312178943.cos-website.ap-chengdu.myqcloud.com//pep/person//20220530223947-157610.jpg', description: '无描述' }]
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrievePhotoList()
  },
  methods: {
    async retrievePhotoList () {
      var pd = { personCode: this.personCode, group: 'PTO', type: 'DTL' }
      this.photoList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(async element => {
          var content = JSON.parse(element.content)
          var path = await this.retrieveImage(content.code)
          var photo = {
            seq: element.seq,
            code: content.code,
            path: path,
            description: content.description
          }
          this.photoList.push(photo)
        })
      }
    },
    async retrieveImage (code) {
      var path = ''
      if (code !== '' && code !== null && code !== undefined) {
        var pd = { code: code }
        const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/retrieveImagePath', this.$qs.stringify(pd))
        if (res.status === '200') {
          path = this.IMAGE_DOMAIN + '/' + res.data.path
        }
      }
      return path
    },
    createPhoto () {
      this.$router.push({ path: '/mde/people/person/dtlPhotoEdit', query: { personCode: this.personCode, seq: this.photoList.length + 1 } })
    },
    updatePhoto (seq) {
      this.$router.push({ path: '/mde/people/person/dtlPhotoEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deletePhoto (seq, ptoCode) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除照片吗?'
      })
        .then(() => {
          this.deletePhotoExecute(seq, ptoCode)
        })
    },
    async deletePhotoExecute (seq, ptoCode) {
      var pd = { key: 'PSN_PTO_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.deleteImage(ptoCode)
        this.retrievePhotoList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    async deleteImage (code) {
      var pd = { code: code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/deleteImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        return true
      } else {
        return false
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.list {
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  text-align: center;
  .description {
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
  }
  .btn {
    border-top: 1px solid #eee;
    padding: 5px;
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 40px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
}
</style>
